<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <template v-if="loading">
                    <div class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                </template>
                <template v-else-if="error">
                    <div class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{ error }}</p>
                    </div><!-- /alert-danger -->
                </template>
                <template v-else>
                    <div class="btn-group w-100">
                        <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                            <i class="fas fa-cogs mr-2"></i>
                            <span>Geral</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                            <i class="fas fa-wand-magic mr-2"></i>
                            <span>Padrões</span>
                        </button>
                    </div>
                    <template v-if="cat == 1">
                        <div class="mt-3 mb-3">
                            <ul class="nav nav-pills nav-fill mb-3" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="pregame-tab" data-toggle="tab" href="#pregame" role="tab" aria-controls="pregame" aria-selected="true">Pré-jogo</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="live-tab" data-toggle="tab" href="#live" role="tab" aria-controls="live" aria-selected="false">Ao vivo</a>
                                </li>
                            </ul>
                            <form @submit.prevent="save">
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="pregame" role="tabpanel" aria-labelledby="pregame-tab">
                                        <div class="form-group mb-3" v-for="(item, index) in percents.pre_jogo" :key="index">
                                            <label class="text-light" :for="`pregame-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                            <input type="text" :id="`pregame-${index}`" v-model="percents.pre_jogo[index]" :readonly="loading == true" @keypress="IsNumber($event, percents.pre_jogo[index])" class="form-control">
                                        </div><!-- /form-group -->   
                                    </div>
                                    <div class="tab-pane fade" id="live" role="tabpanel" aria-labelledby="live-tab">
                                        <div class="form-group mb-3" v-for="(item, index) in percents.ao_vivo" :key="index">
                                            <label class="text-light" :for="`livegame-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                            <input type="text" :id="`livegame-${index}`" v-model="percents.ao_vivo[index]" :readonly="loading == true" @keypress="IsNumber($event, percents.ao_vivo[index])" class="form-control">
                                        </div><!-- /form-group -->   
                                    </div>
                                </div>
                                <button class="btn btn-warning btn-block" type="submit">
                                    <i class="fas fa-save mr-2"></i>Salvar alterações
                                </button>
                            </form>
                        </div>
                    </template>
                    <template v-else>
                        <div class="mt-3 mb-3">
                            <ul class="nav nav-pills nav-fill mb-3" id="myTab2" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="new-tab" data-toggle="tab" href="#new" @click="list = false" role="tab" aria-controls="new" aria-selected="true">Novo padrão</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="list-tab" data-toggle="tab" href="#list" @click="list = true" role="tab" aria-controls="list" aria-selected="false">
                                        Listar<span class="badge badge-light ml-2" v-if="percentsList.length">{{ percentsList.length }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent2">
                                <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
                                    <div class="form-group">
                                        <label for="name" class="text-light">Nome do padrão</label>
                                        <input class="form-control" type="text" id="name" v-model="label" placeholder="Informe o nome do novo padrão" autocomplete="off" spellcheck="false">
                                    </div>
                                    <template v-if="label_len >= 3">
                                        <ul class="nav nav-tabs mb-3" id="myTab3" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="pregame2-tab" data-toggle="tab" href="#pregame2" role="tab" aria-controls="pregame2" aria-selected="true">Pré-jogo</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="live2-tab" data-toggle="tab" href="#live2" role="tab" aria-controls="live2" aria-selected="false">Ao vivo</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent3">
                                            <div class="tab-pane fade show active" id="pregame2" role="tabpanel" aria-labelledby="pregame2-tab">
                                                <div class="form-group mb-3" v-for="(item, index) in newPercents.pre_jogo" :key="item._id">
                                                    <label class="text-light" :for="`pregame2-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                                    <input type="text" :id="`pregame2-${index}`" v-model="newPercents.pre_jogo[index]" :readonly="loading == true" @keypress="IsNumber($event, newPercents.pre_jogo[index])" class="form-control">
                                                </div><!-- /form-group -->   
                                            </div>
                                            <div class="tab-pane fade" id="live2" role="tabpanel" aria-labelledby="live2-tab">
                                                <div class="form-group mb-3" v-for="(item, index) in newPercents.ao_vivo" :key="item._id">
                                                    <label class="text-light" :for="`livegame2-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                                    <input type="text" :id="`livegame2-${index}`" v-model="newPercents.ao_vivo[index]" :readonly="loading == true" @keypress="IsNumber($event, newPercents.ao_vivo[index])" class="form-control">
                                                </div><!-- /form-group -->   
                                            </div>
                                        </div>
                                        <button class="btn btn-success btn-block" @click="newStandard">
                                            <i class="fas fa-check-circle mr-2"></i>Criar padrão
                                        </button>
                                    </template>
                                </div>
                                <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
                                    <template v-if="percentsInit.loading">
                                        <div class="alert alert-warning" role="alert">
                                            <div class="alert-heading text-center mb-0">
                                                <div class="spinner-border mr-2 align-middle" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span>Carregando, aguarde...</span>
                                            </div>
                                        </div><!-- /alert-warning -->
                                    </template>
                                    <template v-else-if="percentsInit.error">
                                        <div class="alert alert-danger" role="alert">
                                            <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                            <hr />
                                            <p class="mb-0">{{ percentsInit.error }}</p>
                                        </div><!-- /alert-danger -->
                                    </template>
                                    <template v-else>
                                        <template v-if="percentsList.length == 0">
                                            <div class="alert alert-danger" role="alert">
                                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                                <hr />
                                                <p class="mb-0">Você ainda não criou nenhuma regra</p>
                                            </div><!-- /alert-danger -->
                                        </template>
                                        <template v-else>
                                            <div class="card mb-3" v-for="(item, index) in percentsList" :key="index">
                                                <div class="card-body">
                                                    <div class="row align-items-center">
                                                        <div class="col-8 text-truncate">{{ item.nome }}</div>
                                                        <div class="col-4">
                                                            <div class="btn-group w-100">
                                                                <button type="button" class="btn btn-primary btn-sm" @click="changeCommissions(item)" role="button">
                                                                    <i class="fas fa-edit"></i>
                                                                </button>
                                                                <button type="button" class="btn btn-danger btn-sm" @click="deletePattern(item)" role="button">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            cat: 1,
            label: '',
            label_len: 0,
            error: '',
            list: false,
            loading: false,
            isLoading: false,
            percents: {
                pre_jogo: {
                    1: 5,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                },
                ao_vivo: {
                    1: 5,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                }
            },
            newPercents: {
                pre_jogo: {
                    1: 20,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                },
                ao_vivo: {
                    1: 5,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                }
            },
            percentsInit: {
                error: '',
                loading: false
            },
            percentsList: [],
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        _onInputExtendedCost($event, key, index) {
            window.console.log(this.newPercents[key])
            this.newPercents[key][index] = $event.target.value;
        },
        save() {

            const self = this;

            if (self.checkIsValue('percents')) {
                Swal.fire(
                    'Atenção!',
                    'Informe todas as comissões!',
                    'warning'
                );
            } 
            else 
            {
                self.error = '';
                self.isLoading = true;

                api.post('dashboard/commission-standard', {
                    percents: self.percents
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            ); 
                        break
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        newStandard() {

            const self = this;

            if (self.label.toString().trim() == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o nome do novo padrão!',
                    'warning'
                );
            } else if (self.checkIsValue('newPercents')) {
                Swal.fire(
                    'Atenção!',
                    'Informe todas as comissões!',
                    'warning'
                );
            } 
            else 
            {
                self.error = '';
                self.isLoading = true;

                api.put('dashboard/commission-standard', {
                    label: self.label,
                    percents: self.newPercents
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'O novo padrão foi criado com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            ); 
                        break
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        getList() {

            const self = this;

            self.percentsInit.error = '';
            self.percentsInit.loading = true;

            api.get('dashboard/commission-standard?action=list').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.percentsList = response.data.comissoes;
                    break;
                    default:
                        self.percentsInit.error = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.percentsInit.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.percentsInit.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.percentsInit.loading = false;
            });
        },
        async changeCommissions(item) {

            const self = this;

            try {

                const html = await self.assembleHTML(item);

                const { value: formValues } = await Swal.fire({
                    title: 'Editar padrão',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <div class="swal-scroll-area">
                            ${html}
                        </div>
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            label: document.getElementById('swal-input-label').value,
                            pregame: {
                                1: parseInt(document.getElementById('swal-input-pregame1').value),
                                2: parseInt(document.getElementById('swal-input-pregame2').value),
                                3: parseInt(document.getElementById('swal-input-pregame3').value),
                                4: parseInt(document.getElementById('swal-input-pregame4').value),
                                5: parseInt(document.getElementById('swal-input-pregame5').value),
                                6: parseInt(document.getElementById('swal-input-pregame6').value),
                                7: parseInt(document.getElementById('swal-input-pregame7').value),
                                8: parseInt(document.getElementById('swal-input-pregame8').value),
                                9: parseInt(document.getElementById('swal-input-pregame9').value),
                                10: parseInt(document.getElementById('swal-input-pregame10').value),
                                11: parseInt(document.getElementById('swal-input-pregame11').value),
                                12: parseInt(document.getElementById('swal-input-pregame12').value),
                                13: parseInt(document.getElementById('swal-input-pregame13').value),
                                14: parseInt(document.getElementById('swal-input-pregame14').value),
                                15: parseInt(document.getElementById('swal-input-pregame15').value),
                                16: parseInt(document.getElementById('swal-input-pregame16').value),
                                17: parseInt(document.getElementById('swal-input-pregame17').value),
                                18: parseInt(document.getElementById('swal-input-pregame18').value),
                                19: parseInt(document.getElementById('swal-input-pregame19').value),
                                20: parseInt(document.getElementById('swal-input-pregame20').value)
                            },
                            inplay: {
                                1: parseInt(document.getElementById('swal-input-inplay1').value),
                                2: parseInt(document.getElementById('swal-input-inplay2').value),
                                3: parseInt(document.getElementById('swal-input-inplay3').value),
                                4: parseInt(document.getElementById('swal-input-inplay4').value),
                                5: parseInt(document.getElementById('swal-input-inplay5').value),
                                6: parseInt(document.getElementById('swal-input-inplay6').value),
                                7: parseInt(document.getElementById('swal-input-inplay7').value),
                                8: parseInt(document.getElementById('swal-input-inplay8').value),
                                9: parseInt(document.getElementById('swal-input-inplay9').value),
                                10: parseInt(document.getElementById('swal-input-inplay10').value),
                                11: parseInt(document.getElementById('swal-input-inplay11').value),
                                12: parseInt(document.getElementById('swal-input-inplay12').value),
                                13: parseInt(document.getElementById('swal-input-inplay13').value),
                                14: parseInt(document.getElementById('swal-input-inplay14').value),
                                15: parseInt(document.getElementById('swal-input-inplay15').value),
                                16: parseInt(document.getElementById('swal-input-inplay16').value),
                                17: parseInt(document.getElementById('swal-input-inplay17').value),
                                18: parseInt(document.getElementById('swal-input-inplay18').value),
                                19: parseInt(document.getElementById('swal-input-inplay19').value),
                                20: parseInt(document.getElementById('swal-input-inplay20').value)
                            }
                        }
                    },
                    onOpen: () => {
                        document.getElementById('swal-input-pregame1').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame2').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame3').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame4').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame5').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame6').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame7').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame8').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame9').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame10').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                        
                        document.getElementById('swal-input-pregame11').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame12').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame13').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame14').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame15').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame16').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame17').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame19').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-pregame20').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay1').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay2').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay3').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay4').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay5').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay6').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay7').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay8').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay9').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay10').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                        
                        document.getElementById('swal-input-inplay11').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay12').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay13').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay14').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay15').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay16').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay17').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });
                        document.getElementById('swal-input-inplay19').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-inplay20').addEventListener("input", (e) => {
                           return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById('swal-input-label').addEventListener("input", (e) => {
                            return e.target.value = e.target.value.replace(/[^a-zà-ú0-9\\.,-\s]+/gi,'');
                        });
                    }
                });

                if (formValues) {

                    const filter_pregame = Object.values(formValues.pregame).some((item) => item.toString() == '');
                    const filter_inplay = Object.values(formValues.inplay).some((item) => item.toString() == '');
                   
                    if (!filter_pregame && !filter_inplay) {

                        self.isLoading = true;

                        api.put('dashboard/commission-standard?action=update', {
                            id: item._id,
                            percents: self.percents,
                            label: formValues.label,
                            inplay: formValues.inplay,
                            pregame: formValues.pregame
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    Swal.fire(
                                        'Parabéns!',
                                        'As alterações foram salvas com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'error'
                                    )
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    } 
                    else 
                    {
                        Swal.fire(
                            'Atenção!',
                            'Preencha todos os campos!',
                            'warning'
                        );
                    }
                }
            }
            catch(e) 
            {
                // window.console.warn(e.message);
            }
        },
        async assembleHTML(item) {
            
            let i = 1;
            let j = 1;
            let str1 = '';
            let str2 = '';
            let index = this.percentsList.findIndex((obj => obj._id == item._id));
            
            try {

                const padrao = this.percentsList[index];

                for (let key in padrao.comissoes.pre_jogo) {
                     let commission = padrao.comissoes.pre_jogo[key];
                     let label = i == 1 ? 'Comissão 1 jogo' : i == 20 ? 'Comissão 20 ou mais...' : `Comissão ${i} jogos`;
                     
                     if (padrao.tipo == 2 || padrao.tipo == 5) {

                        str1 += `<label class="d-block text-left mb-0" style="${i > 1 ? 'display:none!important': ''}">Comissão</label>`;
                        str1 += `<input id="swal-input-pregame${i}" style="${i > 1 ? 'display:none!important': ''}" class="swal2-input" value="${commission}" autocomplete="off" spellcheck="false">`;
                        str1 += `<input id="swal-input-inplay${i}" class="swal2-input d-none" value="${commission}" autocomplete="off" spellcheck="false">`;

                        i++;
                     }
                     else 
                     {
                        str1 += `<label class="d-block text-left mb-0">${label}</label>`;
                        str1 += `<input id="swal-input-pregame${i}" class="swal2-input" value="${commission}" autocomplete="off" spellcheck="false">`;
                         
                        i++;
                     }
                }

                for (let key in padrao.comissoes.ao_vivo) {
                     let commission = padrao.comissoes.ao_vivo[key];
                     let label = j == 1 ? 'Comissão 1 jogo' : j == 20 ? 'Comissão 20 ou mais...' : `Comissão ${j} jogos`;
                     
                     str2 += `<label class="d-block text-left mb-0">${label}</label>`;
                     str2 += `<input id="swal-input-inplay${j}" class="swal2-input" value="${commission}" autocomplete="off" spellcheck="false">`;
                     
                     j++;
                }
                
                return `
                    <p class="mb-3">Você está alterando o padrão de comissões<br><b>"${item.nome}"</b>.</p>
                    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pregame-tab" data-toggle="tab" href="#pregame" role="tab" aria-controls="pregame" aria-selected="true">Pré-jogo</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="inplay-tab" data-toggle="tab" href="#inplay" role="tab" aria-controls="inplay" aria-selected="false">Ao vivo</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="label-tab" data-toggle="tab" href="#label" role="tab" aria-controls="label" aria-selected="false">Nome</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="pregame" role="tabpanel" aria-labelledby="pregame-tab">
                            ${str1}
                        </div>
                        <div class="tab-pane fade" id="inplay" role="tabpanel" aria-labelledby="inplay-tab">
                            ${str2}
                        </div>
                        <div class="tab-pane fade" id="label" role="tabpanel" aria-labelledby="label-tab">
                            <label class="d-block text-left mb-0">Nome do padrão</label>
                            <input type="text" class="swal2-input" id="swal-input-label" value="${padrao.nome}" autocomplete="off" spellcheck="false">
                        </div>
                    </div>
                `;
            } 
            catch(e) 
            {
                // window.console.warn(e.message);
            }
        },
        deletePattern(item) {

            try {

                const self = this;

                Swal.fire({
                    title: 'Excluir padrão',
                    html: `Você deseja mesmo excluir o padrão de comissão<br>"<b>${item.nome}</b>" ?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then(function(result) {

                    if (result.value) {

                        self.isLoading = true;

                        api.put('dashboard/commission-standard?action=delete', {
                            id: item._id,
                            percents: self.percents
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    Swal.fire(
                                        'Parabéns!',
                                        'Padrão excluído com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção',
                    e.message,
                    'error'
                );
            }
        },
        checkIsValue(obj) {

            let stop = false;

            for (let key in this[obj]) {
                
               for (let index in this[obj][key]) {
                   let value = this[obj][key][index];

                   if (value.toString().length == 0) {
                       stop = true;
                       break;
                   } else {
                       this[obj][key][index] = parseInt(value);
                       continue;
                   }
               }
            }

            return stop;
        },
        IsNumber(event) {
            if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
        }
    },
    watch: {
        cat() {
            this.list = false;
        },
        label(value) {
            this.label_len = value.toString().trim().length;
            this.label = value.toString().replace(/[^a-zà-ú0-9\\.,-\s]+/gi,'');
        },
        list(value) {
            if (value) {
                this.getList();
            } else {
                this.percentsList = [];
            }
        },
        percents(values) {

            const percents = {};

            for (let key in values) {
                let obj = {};
                for (let nKey in values[key]) {
                    obj[nKey] = values[key][nKey];
                }
                percents[key] = obj;
            }

            this.newPercents = percents;
        }
    },
    created() {

        const self = this;

        self.error = '';
        self.loading = true;

        api.get('dashboard/commission-standard').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.percents = response.data.comissoes;
                break;
                default:
                    self.error = response.data.message;
                break;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.nav-tabs {
    border-color: #545b62!important;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
    background-color: #5a6268!important;
    border-color: #545b62!important;
    color: #fff!important;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent!important;
}
</style>